import Axios from '../services/axios';

const PATH = 'contracts';

export function show(id: number) {
  return Axios.get(`${PATH}/${id}`);
}

export function listWithTings() {
  return Axios.get(`${PATH}/tings`);
}

export function listWithManual() {
  return Axios.get(`${PATH}`);
}
